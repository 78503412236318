import React, { useState, useEffect } from 'react'
import { createHook } from 'react-sweet-state'
import ReactMarkdown from 'react-markdown'
import { useStaticQuery, graphql } from 'gatsby'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

import Layout from '../components/Layout'

const TermsLayout = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiTermsPage {
        edges {
          node {
            title
            locale
            text
          }
        }
      }
    }
  `)

  const dataLocalized = data.allStrapiTermsPage.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { title, text } = dataLocalized.node

  return (
    <Layout>
      <div class='page'>
        <div class='wrapper'>
          <h1>{title}</h1>
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      </div>
    </Layout>
  )
}

export default TermsLayout
