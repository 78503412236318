import React, { useState, useEffect } from 'react'
import { createHook } from 'react-sweet-state'

import TermsLayout from '../components/TermsLayout'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const TermsPageEn = () => {
  const [state, actions] = useLanguage()
  actions.set('de')

  return <TermsLayout />
}

export default TermsPageEn
